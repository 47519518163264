import React from "react";
import axios from "axios";
import { ReactTagManager } from "react-gtm-ts";
import {
  Card,
  Page,
  Box,
  Layout,
  PageSection,
  Cell,
  Divider,
  Button,
  Text,
  MarketingLayout,
  Image,
  WixDesignSystemProvider,
  Loader,
  EmptyState,
  Badge,
  AnnouncementModalLayout,
  Modal,
  LinearProgressBar,
  Table,
  Notification,
  CardFolderTabs,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import {
  AIFilled,
  ExternalLinkSmall,
  PremiumFilled,
  Reviews,
} from "@wix/wix-ui-icons-common";
import "./App.css";
import ErrorBoundary from "./Components/ErrorBoundary";
import Pro from "./assets/pro.png";
import AltTextThumbnail from "./assets/alt-text-thumbnail.png";
import TextAiThumbnail from "./assets/text-ai-thumbnail.png";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

const baseUrl = "https://api.certifiedco.de/_functions/v1/ai";

const CONTENT = [
  "Everything in Unlimited +",
  "Dedicated onboarding specialist",
  "Optimization sessions with expert",
  "Site down checker & notifier",
  "Priority Support",
  "Setup & optimize for you",
];

const INTERVAL = 4000;

function App() {
  ReactTagManager.init(tagManagerArgs);
  const [dashboard, setDashboard] = React.useState<any>(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const [index, setIndex] = React.useState(0);

  const [isBrokenLinkScannerOpen, setIsBrokenLinkScannerOpen] =
    React.useState(false);
  const [brokenLinkScannerProgress, setBrokenLinkScannerProgress] =
    React.useState(0);
  const [brokenLinkScannerResult, setBrokenLinkScannerResult] = React.useState(
    null as any
  );
  const [brokenLinkScannerSubscribed, setBrokenLinkScannerSubscribed] =
    React.useState(false);

  const [isUpScannerOpen, setIsUpScannerOpen] = React.useState(false);
  const [upScannerProgress, setUpScannerProgress] = React.useState(0);
  const [upScannerResult, setUpScannerResult] = React.useState(null as any);
  const [upScannerSubscribed, setUpScannerSubscribed] = React.useState(false);

  const [appsActiveTabId, setAppsActiveTabId] = React.useState("1");
  const [moreActiveTabId, setMoreActiveTabId] = React.useState("1");

  React.useEffect(() => {
    const loadDashboard = async () => {
      try {
        const instance = new URLSearchParams(window.location.search).get(
          "instance"
        );
        const response = await axios.get(`${baseUrl}/getDashboardByInstance`, {
          params: { instance },
        });
        setDashboard(response.data);

        await Promise.all([
          getBrokenLinksSubscription(),
          getUpSubscription(),
          setIntercomIdentity(),
        ]);
      } catch (error) {
        console.error("Failed to load dashboard:", error);
      }
    };

    loadDashboard();
  }, []);

  const isUpgraded = dashboard?.instance?.instance?.isFree === false;

  const token = new URLSearchParams(window.location.search).get("token");

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=a05e151f-2ee1-4ca0-89cb-7ffeb2d1885c&redirectUrl=https://api.certifiedco.de/_functions/v1/ai/auth`;
  }

  function setIntercomIdentity() {
    fetch(
      "https://api.certifiedco.de/_functions/@certifiedcode/base-backend/intercom",
      {
        method: "GET",
        headers: {
          Authorization:
            new URLSearchParams(window.location.search).get("instance") || "",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      });
  }

  function getBrokenLinks() {
    setBrokenLinkScannerProgress(0);
    setBrokenLinkScannerResult(null);

    const interval = setInterval(() => {
      setBrokenLinkScannerProgress((oldProgress) => {
        if (oldProgress > 90) {
          return oldProgress;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 1000);
    fetch(baseUrl + "/site/brokenlink", {
      method: "POST",
      headers: {
        Authorization:
          new URLSearchParams(window.location.search).get("instance") || "",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setBrokenLinkScannerProgress(100);
          clearInterval(interval);
          setBrokenLinkScannerResult(data);
        } else {
          setBrokenLinkScannerProgress(100);
          clearInterval(interval);
          setBrokenLinkScannerResult({
            timestamp: 1703830330773,
            apiVersion: "1.0.7",
            apiStatus: "success",
            apiCode: 200,
            message:
              "Please publish your site & make sure the site is accessible.",
          });
        }
      });
  }

  function getBrokenLinksSubscription() {
    setIsSaving(true);
    fetch(baseUrl + "/site/getBrokenLinkCron", {
      method: "POST",
      headers: {
        Authorization:
          new URLSearchParams(window.location.search).get("instance") || "",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setBrokenLinkScannerSubscribed(data.enabled);
          setIsSaving(false);
        }
      });
  }

  function createBrokenLinksSubscription() {
    setIsSaving(true);
    fetch(
      baseUrl +
        (brokenLinkScannerSubscribed
          ? "/site/deleteBrokenLinkCron"
          : "/site/createBrokenLinkCron"),
      {
        method: "POST",
        headers: {
          Authorization:
            new URLSearchParams(window.location.search).get("instance") || "",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setBrokenLinkScannerSubscribed(data.enabled);
          setIsSaving(false);
        }
      });
  }

  function getUp() {
    setUpScannerProgress(0);
    setUpScannerResult(null);

    const interval = setInterval(() => {
      setUpScannerProgress((oldProgress) => {
        if (oldProgress > 90) {
          return oldProgress;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 1000);
    fetch(baseUrl + "/status/up", {
      method: "POST",
      headers: {
        Authorization:
          new URLSearchParams(window.location.search).get("instance") || "",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setUpScannerProgress(100);
          clearInterval(interval);
          setUpScannerResult(data);
        } else {
          setUpScannerProgress(100);
          clearInterval(interval);
          setUpScannerResult({
            timestamp: 1703830330773,
            apiVersion: "1.0.7",
            apiStatus: "success",
            apiCode: 200,
            message:
              "Please publish your site & make sure the site is accessible.",
          });
        }
      });
  }

  function getUpSubscription() {
    setIsSaving(true);
    fetch(baseUrl + "/status/getUpCron", {
      method: "POST",
      headers: {
        Authorization:
          new URLSearchParams(window.location.search).get("instance") || "",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setUpScannerSubscribed(data.enabled);
          setIsSaving(false);
        }
      });
  }

  function createUpSubscription() {
    setIsSaving(true);
    fetch(
      baseUrl +
        (upScannerSubscribed ? "/status/deleteUpCron" : "/status/createUpCron"),
      {
        method: "POST",
        headers: {
          Authorization:
            new URLSearchParams(window.location.search).get("instance") || "",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setUpScannerSubscribed(data.enabled);
          setIsSaving(false);
        }
      });
  }

  const tableComp = () => {
    const records = (brokenLinkScannerResult.data || []).filter(
      (v: any) => v.status < 200 || v.status >= 300
    );

    const columns = [
      { title: "Link", render: (row: any) => row.link },
      { title: "Status", render: (row: any) => row.status },
    ];

    return (
      <Table data={records} columns={columns} rowVerticalPadding="medium">
        <Table.Content />
      </Table>
    );
  };

  return (
    <ErrorBoundary>
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        {dashboard && (
          <Notification theme="premium" show={!isUpgraded} type={"global"}>
            <Notification.TextLabel>
              {`Upgrade to unlock "${CONTENT[index]}"`}
            </Notification.TextLabel>
            <Notification.ActionButton
              onClick={() => {
                window.open(dashboard["upgradeUrl"], "_blank");
              }}
            >
              Upgrade
            </Notification.ActionButton>
            <Notification.CloseButton />
          </Notification>
        )}
        <Page height="100vh">
          <Page.Header
            title="SEO with AI: Optimize Faster"
            subtitle="Explore the apps available to you below."
            actionsBar={
              <Box direction="horizontal" gap="SP1">
                <Cell span={4}>
                  {dashboard ? (
                    <Button
                      as="a"
                      href={dashboard["upgradeUrl"]}
                      target="_blank"
                      skin="premium"
                      prefixIcon={<PremiumFilled />}
                    >
                      {isUpgraded ? "Manage Plan" : "Unlock All AI Tools"}
                    </Button>
                  ) : (
                    <Button skin="premium" disabled>
                      Loading...
                    </Button>
                  )}
                </Cell>
                <Cell span={1}>
                  {isUpgraded && (
                    <Button
                      prefixIcon={<Reviews />}
                      skin="inverted"
                      as="a"
                      href="https://bridget.reviews.certifiedcode.us/a05e151f-2ee1-4ca0-89cb-7ffeb2d1885c"
                      target="_blank"
                    >
                      Write a review
                    </Button>
                  )}
                </Cell>
              </Box>
            }
          />
          <Page.Content>
            <Modal isOpen={isBrokenLinkScannerOpen}>
              <AnnouncementModalLayout
                title={
                  brokenLinkScannerResult
                    ? brokenLinkScannerResult.message
                    : "Scanner is running"
                }
                primaryButtonText={brokenLinkScannerResult ? "Done" : "Leave"}
                primaryButtonOnClick={() => {
                  setIsBrokenLinkScannerOpen(false);
                }}
                onCloseButtonClick={() => {
                  setIsBrokenLinkScannerOpen(false);
                }}
              >
                {brokenLinkScannerResult ? (
                  <Box direction="vertical" gap="SP1">
                    {tableComp()}
                  </Box>
                ) : (
                  <>
                    <Text>
                      Discover broken links and pages on your site in seconds.
                      <br />
                      The scanner is running in the background and will notify
                      you when it's done.
                    </Text>
                    <LinearProgressBar value={brokenLinkScannerProgress} />
                  </>
                )}
              </AnnouncementModalLayout>
            </Modal>
            <Modal isOpen={isUpScannerOpen}>
              <AnnouncementModalLayout
                title={
                  upScannerResult
                    ? upScannerResult.message
                    : "Scanner is running"
                }
                primaryButtonText={upScannerResult ? "Done" : "Leave"}
                primaryButtonOnClick={() => {
                  setIsUpScannerOpen(false);
                }}
                onCloseButtonClick={() => {
                  setIsUpScannerOpen(false);
                }}
              >
                {upScannerResult ? (
                  <Box direction="vertical" gap="SP1"></Box>
                ) : (
                  <>
                    <Text>Check if your site is down in seconds.</Text>
                    <LinearProgressBar value={upScannerProgress} />
                  </>
                )}
              </AnnouncementModalLayout>
            </Modal>
            {isUpgraded && (
              <Layout>
                <Cell span={12}>
                  <Card>
                    <MarketingLayout
                      imagePadding={false}
                      size="medium"
                      title="Advanced SEO reports & tools is now a part of SEO with AI"
                      description="Professional SEO reports and web tools software designed to help improve the content, quality, and performance of websites."
                      actions={
                        <Box gap="SP1">
                          <Button
                            size="large"
                            suffixIcon={<AIFilled />}
                            as="a"
                            href={`https://manage.seo.certifiedcode.us/?token=${
                              new URLSearchParams(window.location.search).get(
                                "instance"
                              ) || ""
                            }`}
                            skin="ai"
                          >
                            Join Beta
                          </Button>
                        </Box>
                      }
                      badge={<Badge skin="urgent">Early Beta</Badge>}
                      image={<Image src={Pro} width={600} transparent />}
                    />
                  </Card>
                </Cell>
              </Layout>
            )}
            <Layout>
              <Cell span={12}>
                <Card>
                  <MarketingLayout
                    badge={<Badge skin="warning">New</Badge>}
                    size="tiny"
                    title="UpAI: Better Domain Rating"
                    description="Elevate your site's Domain Rating with UpAI. Get to know how your competitors are preforming and how you can outperform them."
                    actions={
                      <Box gap="SP1">
                        <Button
                          size="small"
                          suffixIcon={<ExternalLinkSmall />}
                          as="a"
                          href="https://go.certifiedcode.global/get/2f7d7db1-4a3c-40c3-9863-c4cba88ce1a7"
                          target="_blank"
                        >
                          Add to Site
                        </Button>
                        <Button
                          size="small"
                          suffixIcon={<ExternalLinkSmall />}
                          skin="inverted"
                          as="a"
                          href="https://www.wix.com/app-market/2f7d7db1-4a3c-40c3-9863-c4cba88ce1a7"
                          target="_blank"
                        >
                          Learn more
                        </Button>
                      </Box>
                    }
                  />
                </Card>
              </Cell>
              <Cell>
                <PageSection title="Apps comes with SEO with AI" showDivider />
              </Cell>
              <Cell span={12}>
                <CardFolderTabs
                  maxTabWidth={"200px"}
                  activeId={appsActiveTabId}
                  onTabChange={(tabId) => setAppsActiveTabId(tabId)}
                >
                  <CardFolderTabs.Tab name="Broken Link Scanner" id="1">
                    <Card.Content>
                      <MarketingLayout
                        title="Broken Link Scanner"
                        description="Scan your site for broken links and pages in seconds."
                        actions={
                          <Box gap="6px">
                            <Button
                              size="small"
                              onClick={() => {
                                setIsBrokenLinkScannerOpen(true);
                                getBrokenLinks();
                              }}
                            >
                              Scan now
                              <Box marginLeft={"SP1"}>
                                <Badge size="tiny" skin="neutralSuccess">
                                  FREE
                                </Badge>
                              </Box>
                            </Button>
                            <Button
                              size="small"
                              disabled={isSaving}
                              skin={!isUpgraded ? "premium" : "inverted"}
                              suffixIcon={
                                !isUpgraded ? <PremiumFilled /> : undefined
                              }
                              onClick={() => {
                                if (isUpgraded) {
                                  createBrokenLinksSubscription();
                                } else {
                                  window.open(dashboard?.upgradeUrl, "_blank");
                                }
                              }}
                            >
                              {brokenLinkScannerSubscribed
                                ? "Unsubscribe"
                                : "Get notified when found"}
                            </Button>
                          </Box>
                        }
                        size="tiny"
                        image={<></>}
                      />
                    </Card.Content>
                  </CardFolderTabs.Tab>

                  <CardFolderTabs.Tab name="Site Monitor" id="2">
                    <Card.Content>
                      <MarketingLayout
                        title="Monitor Site Uptime"
                        description="Get notified when your site is down."
                        actions={
                          <Box gap="6px">
                            <Button
                              size="small"
                              onClick={() => {
                                setIsUpScannerOpen(true);
                                getUp();
                              }}
                            >
                              Check now
                              <Box marginLeft={"SP1"}>
                                <Badge size="tiny" skin="neutralSuccess">
                                  FREE
                                </Badge>
                              </Box>
                            </Button>
                            <Button
                              size="small"
                              disabled={isSaving}
                              skin={!isUpgraded ? "premium" : "inverted"}
                              suffixIcon={
                                !isUpgraded ? <PremiumFilled /> : undefined
                              }
                              onClick={() => {
                                if (isUpgraded) {
                                  createUpSubscription();
                                } else {
                                  window.open(dashboard?.upgradeUrl, "_blank");
                                }
                              }}
                            >
                              {upScannerSubscribed
                                ? "Unsubscribe"
                                : "Get notified when down"}
                            </Button>
                          </Box>
                        }
                        size="tiny"
                        image={<></>}
                      />
                    </Card.Content>
                  </CardFolderTabs.Tab>

                  <CardFolderTabs.Tab name="AI Alt Text" id="3">
                    <Card.Content>
                      <MarketingLayout
                        title="AI Alt Text"
                        description="Generate SEO optimized alt text from images in seconds straight from the Wix and Editor X Editor."
                        actions={
                          <Box gap="6px">
                            <Button
                              as="a"
                              href="https://chrome.google.com/webstore/detail/cpoemachdhbkgamdikhpmiecophfpemk"
                              target="_blank"
                              size="small"
                            >
                              Install Now
                            </Button>
                            <Button
                              size="small"
                              skin="inverted"
                              onClick={() => {
                                window.Intercom("showArticle", 6893567);
                              }}
                            >
                              Learn More
                            </Button>
                          </Box>
                        }
                        size="tiny"
                        image={
                          <Box width="100%" align="right">
                            <Image
                              width="240px"
                              src={AltTextThumbnail}
                              transparent
                            />
                          </Box>
                        }
                      />
                      <Divider />
                      <Box align="left" padding="24px 30px">
                        {dashboard ? (
                          <Text size="small" secondary>
                            {`Current usage on your site: ${
                              dashboard?.instance?.instance?.isFree
                                ? `${
                                    dashboard?.usage?.find(
                                      (v: { type: string }) =>
                                        v.type === "altText"
                                    )?.count || 0
                                  }/15, reset in 24 hours.`
                                : "Unlimited"
                            }`}
                          </Text>
                        ) : (
                          <Loader size="tiny" />
                        )}
                      </Box>
                    </Card.Content>
                  </CardFolderTabs.Tab>

                  <CardFolderTabs.Tab name="AI Title & Desc" id="4">
                    <Card.Content>
                      <MarketingLayout
                        title="AI Title & Desc Generator"
                        description="Generate SEO optimized titles and descriptions in seconds straight from the Wix and Editor X Editor."
                        actions={
                          <Box gap="6px">
                            <Button
                              as="a"
                              href="https://chrome.google.com/webstore/detail/ipniljmjkpmkfgcpklpalkiagpmkgbfj"
                              target="_blank"
                              size="small"
                            >
                              Install Now
                            </Button>
                            <Button
                              size="small"
                              skin="inverted"
                              onClick={() => {
                                window.Intercom("showArticle", 6863400);
                              }}
                            >
                              Learn More
                            </Button>
                          </Box>
                        }
                        size="small"
                        image={
                          <Box width="100%" align="right">
                            <Image
                              width="240px"
                              src={TextAiThumbnail}
                              transparent
                            />
                          </Box>
                        }
                      />
                      <Divider />
                      <Box align="left" padding="24px 30px">
                        {dashboard ? (
                          <Text size="small" secondary>
                            {`Current usage on your site: ${
                              dashboard?.instance?.instance?.isFree
                                ? `${
                                    dashboard?.usage?.find(
                                      (v: { type: string }) =>
                                        v.type === "generator"
                                    )?.count || 0
                                  }/15, reset in 24 hours.`
                                : "Unlimited"
                            }`}
                          </Text>
                        ) : (
                          <Loader size="tiny" />
                        )}
                      </Box>
                    </Card.Content>
                  </CardFolderTabs.Tab>
                </CardFolderTabs>
              </Cell>
            </Layout>
            <Layout>
              <Cell>
                <PageSection title="More from SEO with AI" showDivider />
              </Cell>

              <Cell span={12}>
                <CardFolderTabs
                  maxTabWidth={"200px"}
                  activeId={moreActiveTabId}
                  onTabChange={(tabId) => setMoreActiveTabId(tabId)}
                >
                  <CardFolderTabs.Tab name="Backlinks" id="1">
                    <Card.Content>
                      <MarketingLayout
                        size="tiny"
                        badge={<Badge skin="success">RECOMMENDED</Badge>}
                        title="Hire an expert to help your site rank higher on Google"
                        description="From $83/mo, get a dedicated SEO expert to help you optimize your site for search engines. Note: Additional charges applies."
                        actions={
                          <Box gap="SP1">
                            <Button
                              size="small"
                              suffixIcon={<ExternalLinkSmall />}
                              as="a"
                              href="https://www.certifiedcode.us/pricing/seo?utm_source=seo-with-ai-banner"
                              target="_blank"
                            >
                              Learn more
                            </Button>
                          </Box>
                        }
                        image={<></>}
                      />
                    </Card.Content>
                  </CardFolderTabs.Tab>

                  <CardFolderTabs.Tab name="Citations" id="2">
                    <Card.Content>
                      <MarketingLayout
                        size="tiny"
                        title="Boost your ranking on Google with Citations"
                        description="Get your business listed on 100+ directories and boost your ranking on Google."
                        actions={
                          <Box gap="SP1">
                            <Button
                              size="small"
                              suffixIcon={<ExternalLinkSmall />}
                              as="a"
                              href="https://go.certifiedcode.global/get/28af51f1-3e60-4805-8b98-a9052ebfa7b0"
                              target="_blank"
                            >
                              Add to Site
                            </Button>
                            <Button
                              size="small"
                              suffixIcon={<ExternalLinkSmall />}
                              skin="inverted"
                              as="a"
                              href="https://www.wix.com/app-market/28af51f1-3e60-4805-8b98-a9052ebfa7b0"
                              target="_blank"
                            >
                              Learn more
                            </Button>
                          </Box>
                        }
                      />
                    </Card.Content>
                  </CardFolderTabs.Tab>

                  <CardFolderTabs.Tab name="SEO Checklist" id="3">
                    <Card.Content>
                      <MarketingLayout
                        size="tiny"
                        title="Complete SEO Checklist for your site for $29/mo only"
                        description="We understand that optimizing your website for search engines can be a daunting task, but fear not – we're here to do it for you."
                        actions={
                          <Box gap="SP1">
                            <Button
                              size="small"
                              suffixIcon={<ExternalLinkSmall />}
                              as="a"
                              href="https://www.certifiedcode.us/services/seo-checklist-payment"
                              target="_blank"
                            >
                              Get Started
                            </Button>
                            <Button
                              size="small"
                              suffixIcon={<ExternalLinkSmall />}
                              skin="inverted"
                              as="a"
                              href="https://www.certifiedcode.us/services/seo-checklist?utm_source=seo-with-ai-banner"
                              target="_blank"
                            >
                              Learn more
                            </Button>
                          </Box>
                        }
                        image={<></>}
                      />
                    </Card.Content>
                  </CardFolderTabs.Tab>

                  <CardFolderTabs.Tab name="Media Outreach" id="4">
                    <Card.Content>
                      <MarketingLayout
                        size="tiny"
                        badge={<Badge skin="premium">NEW</Badge>}
                        title="SeenOn: Get Featured on News"
                        description="Catapult your brand with guaranteed publicity. Get featured on 200+ news sites including Digital Journal, Newsmax, Google News, Fox News, and Business Insider."
                        actions={
                          <Box gap="SP1">
                            <Button
                              size="small"
                              suffixIcon={<ExternalLinkSmall />}
                              as="a"
                              href="https://go.certifiedcode.global/get/8d864cca-7715-415d-8fa9-55206d15ae6d"
                              target="_blank"
                            >
                              Add to Site
                            </Button>
                            <Button
                              size="small"
                              suffixIcon={<ExternalLinkSmall />}
                              skin="inverted"
                              as="a"
                              href="https://www.wix.com/app-market/8d864cca-7715-415d-8fa9-55206d15ae6d"
                              target="_blank"
                            >
                              Learn more
                            </Button>
                          </Box>
                        }
                      />
                    </Card.Content>
                  </CardFolderTabs.Tab>
                </CardFolderTabs>
              </Cell>
            </Layout>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    </ErrorBoundary>
  );
}

export default App;
